














































































import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'

// components
import { Query } from '@/components/charts/chart-settings'

// types
import { OrderDetail } from '@/types'
import { NULL_QUERY_VALUE } from '@/api/types'

type Item = OrderDetail
type ValueField = 'postedAmount' | 'unpostedAmount' | 'revenueAmount'
type GroupProps =
  | 'currency'
  | 'company'
  | 'salesArea'
  | 'salesperson'
  | 'commissionQuotaPeriodId'
type Group = Pick<Item, GroupProps>
type GroupOutput = {
  id: number | string
  value: unknown
  items: Item[]
}

export default Vue.extend({
  props: {
    group: Object as PropType<Group>,
    items: Array as PropType<Item[]>,
  },
  computed: {
    commissionComponentGroups(): GroupOutput[] {
      const getValue = (z: Item) => z.commissionComponent.group
      const getId = (z: Item) => getValue(z).id
      return this.doGroups(this.items, getValue, getId)
    },
    baseQuery(): Query {
      return {
        isPosted: 0,
        companyId: this.group.company.id,
        salesAreaId: this.group.salesArea.id,
        salespersonId: this.group.salesperson.id,
        commissionQuotaPeriodId:
          this.group.commissionQuotaPeriodId || NULL_QUERY_VALUE,
        currency: this.group.currency,
      }
    },
  },
  methods: {
    orderDetailsRoute(additionalFilters: Query = {}): RawLocation {
      return {
        name: 'order-details',
        query: Object.entries({
          ...this.baseQuery,
          ...additionalFilters,
        }).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: value.toString(),
          }),
          {}
        ),
      }
    },
    sum(items: Item[], fieldName: ValueField = 'unpostedAmount'): number {
      return items.reduce((prev, item) => prev + item[fieldName], 0)
    },
    doCommissionComponentGroups(items: Item[]): GroupOutput[] {
      const getValue = (z: Item) => z.commissionComponent
      const getId = (z: Item) => getValue(z).id
      return this.doGroups(items, getValue, getId)
    },
    doGroups(
      items: Item[],
      getValue: (z: Item) => unknown,
      getId: (z: Item) => number | string
    ): GroupOutput[] {
      return items.reduce((prev: GroupOutput[], item: Item) => {
        const value = getValue(item)
        const id = getId(item)

        let group = prev.find(p => p.id === id)

        if (!group) {
          group = {
            id: id,
            value: value,
            items: [],
          }
          if (group) prev.push(group)
        }

        if (group) group.items.push(item)

        return prev
      }, [] as GroupOutput[])
    },
  },
})
