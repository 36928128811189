import { ResponseType } from 'axios'

// models
import StorageObject from '@/models/StorageObject'

// types
import { ID, UUID } from '@/types'

export const NULL_QUERY_VALUE = 'NULL'

export type ListFn<T> = (opts?: ListOpts) => Promise<T[]>
export type List<T> = {
  list: ListFn<T>
}
export type ListPaginateFn<T> = (opts?: ListOpts) => Promise<[T[], number]>
export type ListPaginate<T> = {
  list: ListPaginateFn<T>
}

type ListOptValue = string | number | (string | number)[]
export type ListOpts = {
  [key: string]: ListOptValue
}

export type Get<T> = {
  get: (id: ID) => Promise<T>
}

export type Search<T> = {
  search: (q?: string) => Promise<T[]>
}

export type CreateFn<T, R = T> = (input: T) => Promise<R>
export type Create<T, R = T> = {
  create: CreateFn<T, R>
}

export type ReplaceFn<T, R = T> = (id: ID, input: T) => Promise<R>
export type Replace<T, R = T> = {
  replace: ReplaceFn<T, R>
}

export type DeleteFn<R> = (id: ID) => Promise<R>
export type Delete<T, R = T> = {
  delete: (id: ID) => Promise<R>
}

export type UploadAttachments<R = StorageObject[]> = {
  uploadAttachments: (id: ID) => (files: File[]) => Promise<R>
}

export type DeleteAttachment<R = StorageObject> = {
  deleteAttachment: (parentId: ID) => (attachmentId: UUID) => Promise<R>
}

export type Download = {
  download: (opts?: ListOpts, filename?: string) => Promise<unknown>
}

export type OnProgress = (progressEvent: ProgressEvent) => void

export type PivotResult = {
  rows: Array<string | number>
  columns: Array<string | number | null>
  data: Array<Array<number | null>>
}

// function types
export type FetchMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'

export type SimpleItem = {
  [param: string]: unknown
}

export type FetchParams = {
  [param: string]: unknown
}

export type FetchHeaders = FetchParams

export interface FetchOpts {
  onUploadProgress?: OnProgress
  responseType?: ResponseType
}
