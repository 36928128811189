
























































































import { Company, ID, OrderDetail, SalesArea, Salesperson } from '@/types'
import Vue from 'vue'
import log from '@/log'

// components
import BacklogByGroup from '@/components/dashboard/backlog/partials/BacklogByGroup.vue'

// const { group } = config
interface CategoryGroup<Item> {
  id: string
  group: {
    company: Company
    salesArea: SalesArea
    salesperson: Salesperson
    commissionQuotaPeriodId: number
    currency: string
  }
  items: Item[]
}
type Item = OrderDetail
type OrderDetailCategoryGroup = CategoryGroup<Item>

export default Vue.extend({
  metaInfo: {
    title: 'Backlog - Dashboard',
  },
  components: {
    BacklogByGroup,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    items: [] as Item[],

    isFetching: false,
    errors: [] as Error[],
  }),
  watch: {
    period: {
      immediate: true,
      handler() {
        this.getData()
      },
    },
  },
  computed: {
    categoryGroups(): OrderDetailCategoryGroup[] {
      return this.items.reduce((prev, item) => {
        // CO	SALES AREA	SALESPERSON	PERIOD	COMPONENT	CURRENCY
        let g = prev.find(
          p =>
            p.group.company.id === item.company.id &&
            p.group.salesArea.id === item.salesArea.id &&
            p.group.salesperson.id === item.salesperson.id &&
            p.group.commissionQuotaPeriodId === item.commissionQuotaPeriodId &&
            p.group.currency === item.currency
        )
        if (!g) {
          g = {
            id: [
              item.company.id,
              item.salesArea.id,
              item.salesperson.id,
              item.commissionQuotaPeriodId,
              item.currency,
            ].join(':'),
            group: {
              company: item.company,
              salesArea: item.salesArea,
              salesperson: item.salesperson,
              commissionQuotaPeriodId: item.commissionQuotaPeriodId as number,
              currency: item.currency,
            },
            items: [],
          }
          if (g) prev.push(g)
        }
        g?.items.push(item)
        return prev
      }, [] as OrderDetailCategoryGroup[])
    },
  },
  methods: {
    getCommissionQuotaPeriodAbbr(id: ID): string {
      return (
        this.$store.getters.getCommissionQuotaPeriodById(id) || {
          abbr: id.toString(),
        }
      ).abbr
    },
    getData(): void {
      this.isFetching = true
      this.errors = []

      this.$api.orderDetails
        .list({
          isPosted: 0,
          commissionQuotaPeriodId: this.period,
        })
        .then(items => {
          this.items = items
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
