var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticStyle:{"background-color":"var(--v-primary-base)","color":"white"}},[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Total Backlog")]),_c('td',{staticClass:"text-right"},[_vm._v("x "+_vm._s(_vm._f("number")(_vm.items.length)))]),_c('td',{staticClass:"text-right"},[_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":_vm.orderDetailsRoute()}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.sum(_vm.items),_vm.group.currency))+" ")])],1)]),_vm._l((_vm.commissionComponentGroups),function(ref){
          var ccgId = ref.id;
          var ccg = ref.value;
          var ccgItems = ref.items;
return [_c('tr',{key:("ccg-" + ccgId),staticStyle:{"background-color":"rgba(238, 238, 238, 0.6)"}},[_c('td',{staticClass:"pr-0",staticStyle:{"width":"calc(24px + 16px + 0px)"}},[_c('v-icon',[_vm._v("mdi-subdirectory-arrow-right")])],1),_c('td',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(ccg.abbr))]),_c('td',{staticClass:"text-right"},[_vm._v("x "+_vm._s(_vm._f("number")(ccgItems.length)))]),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":_vm.orderDetailsRoute({ commissionComponentGroupId: ccgId })}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.sum(ccgItems),_vm.group.currency))+" ")])],1)]),_vm._l((_vm.doCommissionComponentGroups(ccgItems)),function(ref){
            var ccId = ref.id;
            var cc = ref.value;
            var ccItems = ref.items;
return _c('tr',{key:("cc-" + ccId),staticStyle:{"font-style":"italic"}},[_c('td'),_c('td',{staticClass:"pr-0",staticStyle:{"width":"calc(24px + 16px + 0px)"}},[_c('v-icon',[_vm._v("mdi-subdirectory-arrow-right")])],1),_c('td',[_vm._v(_vm._s(cc.abbr))]),_c('td',{staticClass:"text-right"},[_vm._v("x "+_vm._s(_vm._f("number")(ccItems.length)))]),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":_vm.orderDetailsRoute({
                    commissionComponentGroupId: ccgId,
                    commissionComponentId: ccId,
                  })}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.sum(ccItems),_vm.group.currency))+" ")])],1)])})]})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }